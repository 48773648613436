<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row pb-5">
                        <div class="col-lg-6  mt-2 mb-2">
                            <label>{{$t('cost_center_transactions.accounts')}}</label>
                            <treeselect
                                    :options="accounts"
                                    :load-options="loadOptions"
                                    :multiple="false"
                                    :value="filters.account_id"
                                    @input="updateValue('account_id',$event)"
                                    :searchable="true"
                                    :show-count="true"
                                    :no-children-text="$t('No_sub_options')"
                                    :no-options-text="$t('No_options_available')"
                                    :no-results-text="$t('No_results_found')"
                                    :placeholder="$t('Select')">
                            </treeselect>
                        </div>
                        <div class="col-lg-6  mt-2 mb-2">
                            <label>{{$t('cost_center_transactions.parent_cost_centers')}}</label>
                            <multiselect
                                    v-model="parent_cost_center"
                                    :placeholder="$t('cost_center_transactions.parent_cost_centers')"
                                    label="label"
                                    track-by="id"
                                    :options="cost_center_list"
                                    :multiple="false"
                                    :taggable="false"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false"
                                    @search-change="getCostCenterList($event)">
                            </multiselect>
                        </div>
                        <div class="col-lg-6  mt-2 mb-2">
                            <label>{{$t('cost_center_transactions.secondary_cost_centers')}}</label>
                            <multiselect
                                    v-model="secondary_cost_center"
                                    :placeholder="$t('cost_center_transactions.secondary_cost_centers')"
                                    label="label"
                                    track-by="id"
                                    :options="secondary_cost_center_list"
                                    :multiple="false"
                                    :taggable="false"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false"
                                    >
                            </multiselect>
                        </div>


                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('cost_center_transactions.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('cost_center_transactions.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>

                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary my-auto mb-0 mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger my-auto mb-0 mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::customer-->
        <div class="card card-custom">

            <div class="card-body">
              
                <b-tabs content-class="mt-3">
                    <b-tab :title="$t('cost_center_transactions.summary')">
                        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                            <template slot="child_row" slot-scope="props">
                                <div class="row mt-5">
                                    <div class="col-md-6">
                                        <table class="table table-bordered">
                                            <thead>
                                            <tr style="background: #f0f1f1;">
                                                <th colspan="2">{{$t('cost_center_transactions.transaction')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th style="background-color: #f9f9f9;">{{$t('cost_center_transactions.credit')}}</th>
                                                <td>{{props.row.transaction_credit}}</td>
                                            </tr>
                                            <tr>
                                                <th style="background-color: #f9f9f9;">{{$t('cost_center_transactions.debit')}}</th>
                                                <td>{{props.row.transaction_debit}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-6">
                                        <table class="table table-bordered">
                                            <thead>
                                            <tr style="background: #f0f1f1;">
                                                <th colspan="2">{{$t('cost_center_transactions.balance')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th style="background-color: #f9f9f9;">{{$t('cost_center_transactions.credit')}}</th>
                                                <td>{{props.row.amount_credit}}</td>
                                            </tr>
                                            <tr>
                                                <th style="background-color: #f9f9f9;">{{$t('cost_center_transactions.debit')}}</th>
                                                <td>{{props.row.amount_debit}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </template>
                        </v-server-table>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-cost-center-transactions",
        components: {},
        data() {
            return {
                mainRoute: 'accounting/cost-centers-transactions',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                accounts: [],
                cost_center_list: [],
                secondary_cost_center_list: [],
                filter: {
                    sortBy: 'id',
                },
                filters: {
                    account_id: null,
                    cost_center_id: null,
                    to_date: null,
                    from_date: null,
                },
                columns: ['cost_center_code','cost_center_name'],
                data: [],
                users: [],
                parent_cost_center_id: null,
                parent_cost_center: null,
                secondary_cost_center: null,
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        cost_center_code: that.$t('cost_center_transactions.code'),
                        cost_center_name: that.$t('cost_center_transactions.cost_center'),
                        // transaction: that.$t('cost_center_transactions.transaction'),
                        // amount: that.$t('cost_center_transactions.amount'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        let _data = resp.data.data.data.map((row)=>{
                            row['id'] =  row.type_id+''+row.account_id+''+row.cost_center_id;
                            return row;
                        });
                        return {
                            data: _data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        watch:{
            parent_cost_center: function (val) {
                if (val){
                    this.parent_cost_center_id = val.id;
                    this.getSecondaryCostCenterList(val.id);
                }else {
                    this.parent_cost_center_id = null;
                }
            },
            secondary_cost_center: function (val) {
                if (val){
                    this.filters.cost_center_id = val.id;
                }else {
                    this.filters.cost_center_id = null;
                }
            },
        },
        methods: {
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.account_id = null;
                this.filters.cost_center_id = null;
                this.filters.to_date = null;
                this.filters.from_date = null;
                this.parent_cost_center_id = null;
                this.parent_cost_center = null;
                this.secondary_cost_center = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            
            getCostCenterList(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/cost_centers_without_child`,{params:{filter: filter}}).then((response) => {
                        this.cost_center_list = response.data.data;
                    });
                }else{
                    this.cost_center_list = [];
                }
            },
            getSecondaryCostCenterList(id) {
                ApiService.get(this.mainRouteDependency + "/cost_center_childs",{params:{id: id}}).then((response) => {
                    this.secondary_cost_center_list = response.data.data;
                });
            },

            getAccounts() {
                ApiService.get(this.mainRouteDependency + "/account_parents").then((response) => {
                    this.accounts = response.data.data;
                });
            },

            loadOptions() {
            },
            updateValue(type, value) {
                type == 'account_id' ? this.filters.account_id = value : this.filters.cost_center_id = value;
            },
            parentUpdateValue(type, value) {
                this.parent_cost_center_id = value;
            },
            showDetails(id){
                this.$router.push({name: 'cost_center_transactions.transaction', params:{id: id}});
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.cost_center_transactions")}]);
            this.getAccounts();
            // this.getCostCenterList();
        },
    };
</script>
